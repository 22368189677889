import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.slider_homepage').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1
	});
	
	$('.slider_single_artist').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1
	});
	
	$('.slider_concerts').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1
	});
	
	
});
